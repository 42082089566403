const menu = [
  {
    icon: "globe",
    text: "Browse",
    link: "/",
    dashboard: true,
  },
  // {
  //   icon: "bag",
  //   text: "My Podcast",
  //   link: "",
  //   dashboard: true,
  // },
  // {
  //   icon: "star",
  //   text: "Follow",
  //   link: "",
  //   dashboard: true,
  // },
  {
    icon: "setting",
    text: "Settings",
    link: "/profile",
    dashboard: true,
  },
];
export default menu;
