import React, { useEffect, useRef, useState } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { Icon } from "../components/Component";
import { useAudio } from "./AudioProvider";

const AudioPlayerMusic = ({ songTitle, songSrc, songImg }) => {
  const audioRef = useRef(null);
  const imgRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [rotationAngle, setRotationAngle] = useState(0); // Keep track of rotation angle
  const { audioData } = useAudio(); // Access the audioUrl from context
  const [duration, setDuration] = useState("00:00"); // State to hold the audio duration
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    let animationFrameId;

    const rotate = (timestamp) => {
      if (!imgRef.current) return; // Stop if image ref is not found

      setRotationAngle((prevAngle) => (prevAngle + 1) % 360); // Increment rotation angle

      imgRef.current.style.transform = `rotate(${rotationAngle}deg)`; // Apply rotation

      if (isPlaying) {
        animationFrameId = requestAnimationFrame(rotate); // Continue rotating
      }
    };

    if (isPlaying) {
      animationFrameId = requestAnimationFrame(rotate); // Start rotating when playing
    }

    return () => {
      cancelAnimationFrame(animationFrameId); // Clean up the animation on pause or unmount
    };
  }, [isPlaying, rotationAngle]); // Re-run the effect whenever `isPlaying` or `rotationAngle` changes

  const handlePlay = () => {
    setIsPlaying(true); // Start playing and rotation
  };

  const handlePause = () => {
    setIsPlaying(false); // Stop playing and rotation
  };

  const handleLoadedMetadata = () => {
    const audioElement = audioRef.current.audio.current;
    if (audioElement) {
      setDuration(audioElement.duration); // Set the duration when the metadata is loaded
    }
  };

  // Prevent seeking
  const handleSeek = () => {
    const audioElement = audioRef.current.audio.current;
    audioElement.currentTime = currentTime; // Reset to the previous current time
  };

  const handleTimeUpdate = () => {
    const audioElement = audioRef.current.audio.current;
    setCurrentTime(audioElement.currentTime); // Update the current time as the audio plays
  };

  return (
    <section className="audio-player bottom-10">
      {audioData && audioData.audioUrl ? (
        <AudioPlayer
          ref={audioRef}
          src={audioData.audioUrl}
          customIcons={{
            play: <em className="icon ni ni-play-circle-fill text-dark fs-1"></em>,
            pause: <em className="icon ni ni-pause-circle-fill text-dark fs-1"></em>,
            rewind: <em className="icon ni ni-rewind-fill text-dark fs-3"></em>,
            forward: <em className="icon ni ni-forward-fill text-dark fs-3"></em>,
            volume: <Icon name="vol " className="text-dark" />,
            volumeMute: <Icon name="vol-off" className="text-danger" />,
          }}
          customAdditionalControls={[
            RHAP_UI.LOOP,
            <>
              {audioData ? (
                <div className="current-song-play d-flex align-items-center">
                  <img
                    ref={imgRef}
                    src={audioData.image}
                    className={`img-fluid border-radius-50 px-1 ${isPlaying ? 'rotating' : ''}`}
                    width={"50px"}
                    height={"50px"}
                    alt="song-thumbnail"
                  />
                  <p className="text-dark">Now Playing: {audioData.name}</p>
                </div>
              ) : (
                <p>No audio available</p>
              )}
            </>,
          ]}
          onPlay={handlePlay} // Trigger play
          onPause={handlePause} // Trigger pause
          onLoadedMetadata={handleLoadedMetadata} // Event to get the duration
          onSeeked={handleSeek} // Prevent seeking
          onListen={handleTimeUpdate} // Track the current time
        />
      ) : (
        <p></p> // Optionally, show a message or hide this part altogether
      )}
    </section>
  );
};

export default AudioPlayerMusic;
